import React, { useEffect, useState } from 'react';
import { User, Country, City } from 'src/types';
import { FiMenu, FiX } from 'react-icons/fi';
import { AppRoutes, AdminRoutes } from 'src/lib/routes';
import classNames from 'classnames';
import ClientOnlyPortal from 'src/components/ClientOnlyPortal';
import useBreakpoints from 'src/hooks/useBreakpoints';
import Destinations from 'src/components/Destinations';
import Link from 'next/link';
import useLogout from 'src/hooks/useLogout';
import { useRouter } from 'next/router';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { AboutItems } from 'src/components/AboutDropdown';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import useCurrentCurrency from 'src/hooks/useCurrentCurrency';
import { ListCurrency } from 'src/components/CurrencySelector';
import useCheckUnreadMessage from 'src/hooks/useCheckUnreadMessage';

type Props = {
  currentUser?: User | undefined;
  cities: Record<number, City[]>;
  countries: Country[];
};

type SidenavProps = Props;

const MobileSidenavAndButton: React.FC<SidenavProps> = ({ currentUser, cities, countries }) => {
  const { isScreenLg } = useBreakpoints();
  const [showSidenav, setShowSidenav] = useState(false);
  const userSignedIn = !!currentUser;
  const [isSelectedCountry, setIsSelectedCountry] = useState(false);
  const [isSelectedAbout, setIsSelectedAbout] = useState(false);
  const [isSelectedCurrency, setIsSelectedCurrency] = useState(false);
  const currentCurrency = useCurrentCurrency();
  const logout = useLogout();
  const router = useRouter();
  const hasUnreadMessage = useCheckUnreadMessage(showSidenav)

  const close = () => {
    setShowSidenav(false);
  };

  useEffect(() => {
    if (router.isReady) {
      const toggle = () => {
        setShowSidenav(false);
        const sideNavContent = document.querySelector('.sidenav__content');
        if (sideNavContent) {
          enableBodyScroll(sideNavContent);
        }
      };
      router.events.on('routeChangeComplete', toggle);

      return () => {
        router.events.off('routeChangeComplete', toggle);
      };
    }
  }, [router]);

  return isScreenLg ? null : (
    <>
      <button
        className="tw-block tw-px-0 tw-outline-none tw-bg-transparent"
        type="button"
        aria-label="Toggle navigation"
        onClick={() => {
          const sideNavContent = document.querySelector('.sidenav__content');
          if (showSidenav) {
            setShowSidenav(false);
            if (sideNavContent) {
              enableBodyScroll(sideNavContent);
            }
          } else {
            setShowSidenav(true);
            if (sideNavContent) {
              disableBodyScroll(sideNavContent);
            }
          }
        }}
      >
        {showSidenav ? (
          <FiX size={28} className="tw-text-brand-navy" />
        ) : (
          <FiMenu size={28} className="tw-text-brand-navy" />
        )}
      </button>
      <ClientOnlyPortal selector="#modal-sidenav">
        <div
          className={classNames('sidenav', { active: showSidenav })}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            if (e.target instanceof HTMLElement) {
              if (
                e.target.closest('.sidenav__content') ||
                e.target.classList.contains('sidenav__content')
              ) {
                return;
              } else {
                close();
              }
            }
          }}
        >
          <div className="sidenav__inner">
            <div className="sidenav__content">
              <div className="sidenav__body">
                <div className="sidenav__section">
                  <div
                    className={classNames('tw-p-4', {
                      'tw-hidden': isSelectedAbout || isSelectedCurrency,
                    })}
                  >
                    <Destinations
                      cities={cities}
                      countries={countries}
                      onSelect={(country) => {
                        setIsSelectedCountry(country ? true : false);
                      }}
                    />
                  </div>
                  <div className={classNames('tw-p-4', { 'tw-hidden': !isSelectedAbout })}>
                    <div
                      className="tw-flex tw-items-center tw-cursor-pointer hover:tw-font-semibold tw-mb-6"
                      onClick={() => {
                        setIsSelectedAbout(false);
                      }}
                    >
                      <FiChevronLeft size={18} className="tw-mr-2 tw-align-top" />
                      <span className="tw-text-sm tw-leading-4">Back to Home</span>
                    </div>

                    <div className="tw-border-t tw-border-grey-lighter tw-mb-6"></div>

                    <div className="tw-font-semibold tw-font-styleSans tw-text-3xl tw-text-brand-navy tw-mb-3 tw-leading-none">About Us</div>

                    <AboutItems />
                  </div>
                  <div className={classNames('tw-p-4', { 'tw-hidden': !isSelectedCurrency })}>
                    <div
                      className="tw-flex tw-items-center tw-cursor-pointer hover:tw-font-semibold tw-mb-6"
                      onClick={() => {
                        setIsSelectedCurrency(false);
                      }}
                    >
                      <FiChevronLeft size={18} className="tw-mr-2 tw-align-top" />
                      <span className="tw-text-sm tw-leading-4">Back to Home</span>
                    </div>

                    <div className="tw-border-t tw-border-grey-lighter tw-mb-6"></div>

                    <div className="tw-font-semibold tw-font-styleSans tw-text-3xl tw-text-brand-navy tw-mb-3 tw-leading-none">Choose Currency</div>

                    <div className="tw-font-semibold">
                      <ListCurrency />
                    </div>
                  </div>
                  <div
                    className={classNames('tw-pb-4', {
                      'tw-hidden': isSelectedCountry || isSelectedAbout || isSelectedCurrency,
                    })}
                  >
                    <div className="tw-px-4">
                      <div className="tw-mt-3 tw-flex tw-items-center">
                        <Link href={AppRoutes.giftsRoute().toPath()}>
                          <a className="tw-text-brand-navy tw-font-styleSans tw-text-3xl tw-font-semibold hover:tw-underline tw-leading-none">
                            Gifts
                          </a>
                        </Link>
                      </div>
                      <div
                        onClick={() => setIsSelectedAbout(true)}
                        className="tw-flex tw-items-center tw-cursor-pointer tw-mt-3"
                      >
                        <span className="tw-text-brand-navy tw-text-3xl tw-font-semibold tw-font-styleSans tw-leading-none">
                          About
                        </span>
                        <FiChevronRight size={20} />
                      </div>

                      <div className="tw-mt-3">
                        <Link href={AppRoutes.magazinesRoute().toPath()}>
                          <a className="tw-text-brand-navy tw-font-styleSans tw-text-3xl tw-font-semibold hover:tw-underline tw-leading-none">
                            Field Notes
                          </a>
                        </Link>
                      </div>

                      <div className="tw-mt-3">
                        {userSignedIn ? (
                          <Link href={AppRoutes.accountProfileRoute().toPath()}>
                            <a className="tw-text-brand-navy tw-text-lg tw-font-semibold tw-font-styleSans tw-leading-none hover:tw-underline">
                              Profile
                            </a>
                          </Link>
                        ) : (
                          <Link href={AppRoutes.loginRoute().toPath()}>
                            <a className="tw-text-brand-navy tw-text-lg tw-font-semibold tw-font-styleSans tw-leading-none hover:tw-underline">
                              Login
                            </a>
                          </Link>
                        )}
                      </div>
                      {userSignedIn ? (
                        <div className="tw-relative tw-mt-3">
                          {hasUnreadMessage ? (
                            <div className="tw-bg-orange tw-h-2.5 tw-w-2.5 tw-rounded-full tw-absolute tw-top-0.5 tw-left-18"></div>
                          ): null}
                          <Link href={AppRoutes.chatRoute().toUrl()}>
                            <a className="tw-text-brand-navy tw-text-lg tw-font-semibold tw-font-styleSans tw-leading-none hover:tw-underline">
                              Messages
                            </a>
                          </Link>
                        </div>
                      ) : null}
                      {currentUser?.isAdmin && (
                        <div className="tw-mt-3">
                          <Link href={AdminRoutes.adminRoute().toUrl()}>
                            <a className="tw-text-brand-navy tw-text-lg tw-font-semibold tw-font-styleSans tw-leading-none hover:tw-underline">
                              Admin Dashboard
                            </a>
                          </Link>
                        </div>
                      )}
                      {currentUser?.isPartner && (
                        <div className="tw-mt-3">
                          <Link href={AdminRoutes.partnerRoute().toUrl()}>
                            <a className="tw-text-brand-navy tw-text-lg tw-font-semibold tw-font-styleSans tw-leading-none hover:tw-underline">
                              Partner Dashboard
                            </a>
                          </Link>
                        </div>
                      )}
                      {userSignedIn && (
                        <div
                          className="tw-text-brand-navy tw-text-lg tw-font-semibold tw-font-styleSans tw-leading-none tw-mt-3 tw-cursor-pointer"
                          onClick={() => {
                            setShowSidenav(false);
                            const sideNavContent = document.querySelector('.sidenav__content')
                            if (sideNavContent) {
                              enableBodyScroll(sideNavContent);
                            }
                            logout();
                          }}
                        >
                          <a>Logout</a>
                        </div>
                      )}

                      <div className="tw-mt-3">
                        <div
                          className="tw-flex tw-items-center tw-text-brand-navy tw-text-lg tw-font-semibold tw-font-styleSans tw-leading-none tw-cursor-pointer"
                          onClick={() => setIsSelectedCurrency(true)}
                        >
                          <span>{currentCurrency}</span>
                          <FiChevronRight size={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ClientOnlyPortal>
    </>
  );
};

export default MobileSidenavAndButton;
